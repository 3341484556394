@import "bootstrap.css";

body.base {
    text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
    /* box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5); */
}

body.user {
    background-color: #333;
    text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
}

main {
    padding: 3%;
}

.cover-container {
    max-width: 100em;
}

.nav-masthead .nav-link {
    padding: .25rem 0;
    font-weight: 700;
    color: rgba(255, 255, 255, .5);
    background-color: transparent;
    border-bottom: .25rem solid transparent;
}

.nav-masthead .profile {
    padding: .25rem 0;
    font-weight: 700;
    color: rgba(255, 255, 255, .5);
    background-color: transparent;
    text-decoration: none;
}

.nav-masthead .nav-link:hover, .nav-masthead .nav-link:focus {
    border-bottom-color: rgba(255, 255, 255, .25);
}

.nav-masthead .nav-link + .nav-link {
    margin-left: 1rem;
}

.nav-masthead .active {
    color: #fff;
    border-bottom-color: #fff;
}

.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}

.btn-secondary, .btn-secondary:hover, .btn-secondary:focus {
    color: #333;
    text-shadow: none;
}
